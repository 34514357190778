import React, { createContext, useContext, useEffect, useState } from 'react';
import Prismic from 'prismic-javascript';
import { useParams, useHistory } from 'react-router-dom';
import PrismicDom from 'prismic-dom';
import { format, parseISO } from 'date-fns';

const CmsContext = createContext(null);

const CmsProvider = ({children}) => {
  let { slug } = useParams();
  const history = useHistory();
  const [doc, setDocData] = useState(null);

  const apiEndpoint = 'https://cupom.cdn.prismic.io/api/v2';
  
  useEffect(() => {
    const Client = Prismic.client(apiEndpoint);
    const fetchData = async () => {
      try {
        const response = await Client.getByUID('cupomdesconto', slug);
        const { data:doc, uid } = response;

        const dados = {
          uid,
          condicaoum: PrismicDom.RichText.asText(doc.condicaoum),
          condicaodois: PrismicDom.RichText.asText(doc.condicaodois),
          desconto: PrismicDom.RichText.asText(doc.desconto),
          codigocupom: PrismicDom.RichText.asText(doc.codigocupom),
          estabelecimento: PrismicDom.RichText.asText(doc.estabelecimento),
          validade: format(parseISO(doc.validade), "'Valido até' dd/MM/yyyy")
        };
        
        setDocData(dados);
      } catch(e) {
           history.push('/cupom-invalido');
      }
      
    };

    fetchData();
  }, [history, slug])

  return (
    <CmsContext.Provider value={{dados: doc}}>
      {children}
    </CmsContext.Provider>
  )
}

const useCmsData= () => {
  const context = useContext(CmsContext);

  return context;
}

export { CmsProvider, useCmsData };